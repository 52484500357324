import React, { useState, useEffect } from "react";
import Sidebar from "./components/SideBar";
import SideBarNew from "./components/SideBarNew";
import SideBarNew2 from "./components/SideBarNew2";
import RightSidebar from "./components/InfoBarRight";
import Map from "./components/Map";
import ComparisonMap from "./components/ComparisonMap";
import Login from "./components/Login";
import Wait from "./components/Wait";
import OperatorMap from "./components/MapOperator";
import "./App.css";
import "antd/dist/antd.css";
import { Row, Col, Skeleton, notification, Button } from "antd";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { polyfill } from "h3-js";
import moment from "moment";
import axios from "axios";

function App() {
  const URL = "https://api.revolutionapp.io/api/qos/";
  // const URL = "http://localhost:3004/";

  const [Cities, UpdateCities] = useState([]);
  const [SelectedCity, UpdateSelectedCity] = useState(null);
  const [SelectedOperator, UpdateSelectedOperator] = useState(null);
  const [SelectedMeasure, UpdateSelectedMeasure] = useState(null);
  const [Coverage, UpdateCoverage] = useState(0);
  const [AllHex, UpdateAllHex] = useState([]);
  const [InterOperator, UpdateInterOperator] = useState([]);
  const [OperatorAvg, UpdateOperatorAvg] = useState([]);
  const [StatsBar, UpdateStatsBar] = useState(false);
  const [RFSamples, UpdateRFSamples] = useState(0);
  const [ActiveTests, UpdateActiveTests] = useState(0);

  const [ComparisonDate, UpdateComparisonDate] = useState({
    first: null,
    second: null,
  });
  const [Operators, UpdateOperators] = useState([]);
  const [Compare, UpdateCompare] = useState(false);
  const [Hexagons, UpdateHexagons] = useState([]);
  const [SelectedHexagons, UpdateSelectedHexagons] = useState([]);
  const [Polygon, UpdatePolygon] = useState([]);
  const [Reset, UpdateReset] = useState(false);

  const [CallApi, UpdateCallApi] = useState(false);
  const [HexComaparison, UpdateHexComparison] = useState({
    IncreasedBins: [],
    DecreasedBins: [],
    NewBins: [],
    TotalBins: 0,
  });
  const [Loading, UpdateLoading] = useState(false);
  const [Filters, UpdateFilters] = useState({
    city: null,
    operator: null,
    measurement: "DLThroughput",
    date: {
      first: moment(new Date(), "YYYY-MM-DD").subtract(2, "month"),
      second: moment(new Date(), "YYYY-MM-DD").add(10, "month"),
    },
    call: false,
    coordinates: [],
  });

  const openNotificationWithIcon = (title, message, type) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    if (Reset) {
      UpdatePolygon([]);
      UpdateOperators([]);
      UpdateHexagons([]);
      UpdateHexComparison({
        IncreasedBins: [],
        DecreasedBins: [],
        NewBins: [],
        TotalBins: 0,
      });
      UpdateReset(false);
    }
  }, [Reset]);

  useEffect(async () => {
    if (CallApi) {
      if (!Polygon.length) {
        openNotificationWithIcon(
          "No Area!",
          "Please select a location!",
          "error"
        );
      } else if (!Filters.date) {
        openNotificationWithIcon(
          "Select Date",
          "Please select date range to proceed",
          "info"
        );
      } else {
        console.log("Calling API....", Polygon);
        //make hex call for API
        Filters.coordinates = Polygon;
        Filters.operator = "All";
        Filters.measurement = "DLThroughput";

        UpdateLoading(true);
        let coordinates = [];
        Polygon.map((i, x) => {
          coordinates.push([i[1], i[0]]);
        });
        // try {

        // } catch () {

        // }
        // const hexagons = polyfill(coordinates, 8);
        // console.log(hexagons);
        let hexData = await axios.post(URL + "hexagons", Filters);
        let hexDataOperatorComparison = await axios.post(
          URL + "inter-operator",
          Filters
        );
        let sampleCount = await axios.post(URL + "rf-count");
        console.log("Samples", sampleCount.data[0].samples);
        if (sampleCount.data.length) {
          UpdateRFSamples(sampleCount.data[0].samples);
        }
        if (hexDataOperatorComparison.data) {
          UpdateInterOperator(hexDataOperatorComparison.data.data);
          UpdateOperatorAvg(hexDataOperatorComparison.data.avg);
          UpdateActiveTests(
            hexDataOperatorComparison.data.avg.reduce(
              (n, { samples }) => n + samples,
              0
            )
          );
        }

        // if (hexData.data.HexComparison) {
        //   UpdateHexComparison({
        //     Combined: hexData.data.HexDataCombined,
        //     IncreasedBins: hexData.data.HexComparison["IncreasedBins"],
        //     DecreasedBins: hexData.data.HexComparison["DecreasedBins"],
        //     NewBins: hexData.data.HexComparison["NewBins"],
        //     UnChangedBins: hexData.data.HexComparison["UnChangedBins"],
        //     TotalBins: hexData.data.HexDataCombined.length,
        //     TotalAverage: hexData.data.HexComparison["TotalAverage"],
        //   });
        // }
        // console.log("Total Hex", hexagons.length);
        console.log("Covered Hex", hexData.data.HexData.length);
        // console.log(
        //   "coverage percentage",
        //   (hexData.data.HexData.length * 100) / hexagons.length
        // );

        /**
         * @hexagons
         */
        // let totalHex = [];
        // hexagons.forEach(function (id) {
        //   totalHex.push({
        //     id,
        //     count: 0,
        //     avg: 0,
        //   });
        // });
        // UpdateAllHex(totalHex);
        // UpdateCoverage((hexData.data.HexData.length * 100) / hexagons.length);
        if (hexData.data.HexData.length == 0) {
          openNotificationWithIcon(
            "No Data",
            "Please search with other options!",
            "info"
          );
        }

        UpdateHexagons(hexData.data.HexData);
        UpdateLoading(false);
      }
      UpdateCallApi(false);
    }
  }, [CallApi]);

  return (
    <>
      {/* {Cities.length === 0 ? (
        <Skeleton active paragraph={{ rows: 10 }} title={true} />
      ) : ( */}
      <>
        <BrowserRouter>
          <Switch>
            <Route path="/operators">
              <OperatorMap hexagons={InterOperator} />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <RightSidebar
                filters={Filters}
                updatefilters={UpdateFilters}
                cities={Cities}
                updatepolygon={UpdatePolygon}
                polygon={Polygon}
                operators={Operators}
                selectmeasurement={UpdateSelectedMeasure}
                selectoperator={UpdateSelectedOperator}
                selectcity={UpdateSelectedCity}
                loading={Loading}
                hexagons={Hexagons.length}
                comparison={ComparisonDate}
                updatecomparson={UpdateComparisonDate}
                call={UpdateCallApi}
                updatecompare={UpdateCompare}
                compare={Compare}
                updatereset={UpdateReset}
                reset={Reset}
              />
              <Row>
                {/* <Col span={3}> */}

                {/* </Col> */}
                {Hexagons.length ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 4,
                        left: "18%",
                        top: "20px",
                      }}
                    >
                      {" "}
                      <Button
                        size="default"
                        type="default"
                        shape="round"
                        onClick={() => UpdateStatsBar(!StatsBar)}
                      >
                        Sampling Statistics
                      </Button>
                      {StatsBar ? (
                        <>
                          <div>
                            <div
                              style={{
                                backgroundColor: "black",
                                opacity: ".7",
                                height: "100vh",
                                width: "100%",
                                left: "0px",
                                top: "0px",
                                position: "fixed",
                                zIndex: 10,
                              }}
                            ></div>
                            <SideBarNew
                              samples={RFSamples}
                              tests={ActiveTests}
                              bar={StatsBar}
                              updatebar={UpdateStatsBar}
                            />
                          </div>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Col span={4} className="pointer">
                      {" "}
                      <SideBarNew2 avg={OperatorAvg} hexagons={InterOperator} />
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col span={Hexagons.length ? 18 : 20}>
                  <OperatorMap hexagons={InterOperator} avg={OperatorAvg} />
                </Col>
              </Row>
            </Route>
          </Switch>
        </BrowserRouter>{" "}
      </>
    </>
  );
}

export default App;
